import React from "react";

export const travauxCardsData = [
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Réflexion sur",
    titleWeak: "la rue de L’Yvette",
    description:
      "Nous nous interrogeons sur la possibilité de diminuer voire de limiter la circulation sur une partie la rue de l’Yvette. L’objectif de cette modification sera de rendre le centre-ville plus piétonnier et surtout d’offrir un parcours prioritaire pour les modes doux (cycles, piétons, etc.) entre le Lac du Mail, le parc de la Grande Bouvèche en passant par le parc Charles Boucher.",
    thumbnail: "c1_default.png",
    imgSrc: "c1_default.png",
    planMasse: "c1_plan.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Parmi ces propositions, laquelle vous semble la mieux adaptée ?",
        options: [
          {
            label:
              "Piétonnisation totale de cette portion de la rue de l’Yvette.",
            value: "q1rep1",
          },
          {
            label:
              "Priorité aux modes doux : La rue est réaménagée et les piétons et cycles sont prioritaires.",
            value: "q1rep2",
          },
          {
            label:
              "Piétonnisation certains jours (Weekend et jours fériés et les jours de marché).",
            value: "q1rep3",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Réflexion sur",
    titleWeak: "la place de la mairie",
    description:
      "Plutôt parking que place, le parvis de la mairie doit se réinventer avec ce projet. L’offre de stationnement actuelle située devant l’hôpital va être déplacée dans le nouveau parking en sous-sol du marché.",
    thumbnail: "c2_default.png",
    imgSrc: "c2_default.png",
    planMasse: "c2_plan.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Parmi ces propositions, laquelle vous semble la mieux adaptée ?",
        options: [
          {
            label: "Une place minérale pour permettre de nouveaux usages.",
            value: "q1rep1",
          },
          {
            label:
              "Une place essentiellement minérale avec de nombreux arbres.",
            value: "q1rep2",
          },
          {
            label: "Une place végétale.",
            value: "q1rep3",
          },
          {
            label: "Une forêt urbaine.",
            value: "q1rep4",
          },
        ],
        value: "q1rep1",
      },
      {
        id: "q2",
        type: "radio",
        title: "Quel mobilier souhaitez-vous sur cette place ?",
        options: [
          {
            label: "Des bancs, des chaises...",
            value: "q2rep1",
          },
          {
            label: "Des jeux pour enfants.",
            value: "q2rep2",
          },
          {
            label: "Des jeux pour adultes.",
            value: "q2rep3",
          },
          {
            label: "Des agrès sportifs",
            value: "q2rep4",
          },
          {
            label: "Des oeuvres d’art",
            value: "q2rep5",
          },
        ],
        value: "q2rep1",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Accueillir la biodiversité",
    titleWeak: "à côté des voies SNCF",
    description:
      "De part et d’autre des voies SNCF se situent des espaces arborés intéressants pour la biodiversité. Ces zones non constructibles et non accessibles pourraient être améliorées afin de permettre un développement de la biodiversité. Un écologue missionné pour travailler au renforcement de la biodiversité sur ce futur quartier suggère plusieurs aménagements.",
    thumbnail: "c3_default.png",
    imgSrc: "c3_default.png",
    planMasse: "c3_plan.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Souhaitez-vous un remplacement de vos volets métalliques dans votre logement ?",
      options: {
        q1rep1: {
          label: "Non, une simple révision suffira",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Parmi ces propositions, laquelle vous semble la mieux adaptée ?",
        options: [
          {
            label:
              "Des nichoirs à oiseaux attirent et aident les oiseaux à se maintenir sur le site et vous offrent une ambiance appréciée. Ce sont également des alliés pour réduire les insectes nuisibles.",
            value: "q1rep1",
          },
          {
            label:
              "Des gîtes à hérisson utiles dans les jardins et potagers car ils consomment une grande quantité d’invertébrés (escargots, limaces, scarabées…)",
            value: "q1rep2",
          },
          {
            label:
              "Des gîtes à insectes permettent la pollinisation des plantes, processus indispensable pour la production de fruits et légumes et éliminent des espèces ravageurs ( pucerons, vers blanc…) Ils sont donc une alternative à l’utilisation de pesticides.",
            value: "q1rep3",
          },
          {
            label: "Les trois.",
            value: "q1rep4",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "nc1",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Réflexion sur",
    titleWeak: "le stationnement",
    description:
      "Le stationnement au niveau de la Mairie est amené à être réorganisé. Il est aujourd’hui organisé de la manière suivante :",
    jsxDescription: (
      <React.Fragment>
        <div>
          <p>
            Le stationnement au niveau de la Mairie est amené à être réorganisé.
          </p>
          <br />
          <p>Il est aujourd’hui organisé de la manière suivante :</p>
          <ul>
            <li>
              <span style={{ color: "#e31869", fontWeight: "bold" }}>
                32 places
              </span>{" "}
              devant l'hôpital
            </li>
            <li>
              <span style={{ color: "#e31869", fontWeight: "bold" }}>
                30 places
              </span>{" "}
              derrière la mairie
            </li>
            <li>
              <span style={{ color: "#e31869", fontWeight: "bold" }}>
                65 places
              </span>{" "}
              entre le marché et l’yvette
            </li>
          </ul>
          <br />
          <p>
            Nous prévoyons de construire un parking de 150 places en sous-sol,
            sous le nouveau marché.
          </p>
          <br />
          <p>
            Le stationnement sera gratuit les jours de marché et le weekend.
          </p>
          <br />
          <p>
            Une trentaine de places de stationnement sera conservée en surface
            notamment pour faciliter le stationnement pour l’école
            Sainte-Suzanne aujourd’hui difficile.
          </p>
        </div>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC1_thumb.png",
    video: {
      url: "https://youtu.be/nhSwFnkwvQI",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
];
