import app, { db } from "../config/firebase";
import moment from "moment";

// Codes
export const codesLoc = [
  "504009",
  "758616",
  "144308",
  "920166",
  "446640",
  "613127",
  "505654",
  "745511",
  "558014",
  "810413",
  "175133",
  "217003",
  "627200",
  "956033",
  "962064",
  "394572",
  "491856",
  "701273",
  "449075",
  "120349",
  "203359",
  "889947",
  "640262",
  "145902",
  "356156",
  "512394",
  "873278",
  "423295",
  "595313",
  "238576",
  "800832",
  "148537",
  "416596",
  "891047",
  "475629",
  "841478",
  "731249",
  "840704",
  "916661",
  "617211",
  "891430",
  "112277",
  "177130",
  "472515",
  "943143",
  "462543",
  "662745",
  "446043",
  "336892",
  "632140",
  "858945",
  "527111",
  "487599",
  "103564",
  "374727",
  "921144",
  "206293",
  "263065",
  "154371",
  "402325",
  "931849",
  "626753",
  "908113",
  "168362",
  "143792",
  "758377",
  "271078",
  "415464",
  "483577",
  "795484",
  "420748",
  "852275",
  "177541",
  "622536",
  "838812",
  "691157",
  "507081",
  "847240",
  "221182",
  "285206",
  "199256",
  "874210",
  "240265",
  "965611",
  "705210",
  "538428",
  "846175",
  "958976",
  "999434",
  "880777",
  "930111",
  "198534",
  "549751",
  "946570",
  "858811",
  "505703",
  "500458",
  "578828",
  "247700",
  "884165",
  "271301",
  "940493",
  "273035",
  "442894",
  "200388",
  "373576",
  "826320",
  "177762",
  "526304",
  "568749",
  "182116",
  "301172",
  "386248",
  "325658",
  "547662",
  "479301",
  "998055",
  "742818",
  "627168",
  "114707",
  "411527",
  "421934",
  "986289",
  "439242",
  "598492",
  "668527",
  "971007",
  "680364",
  "677569",
  "947921",
  "947581",
  "381119",
  "762383",
  "402347",
  "913100",
  "506680",
  "585532",
  "653990",
  "759764",
  "392693",
  "805232",
  "203458",
  "809628",
  "443021",
  "767943",
];

// Checks for validity
export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  if (rules.codes) {
    isValid = rules.codes.includes(value) && isValid;
  }

  return isValid;
};

// Submits the phone number to firebase and sets the confirmation result
export const submitPhoneNumber = (dispatch, numTel, appVerifier) => {
  let telephone = `+33${numTel.value}`;
  app
    .auth()
    .signInWithPhoneNumber(telephone, appVerifier)
    .then((confirmationResult) => {
      // window.confirmationResult = confirmationResult;
      // setConfResult(confirmationResult);
      dispatch({ type: "SET_CONFRESULT", confResult: confirmationResult });
      dispatch({ type: "SET_SMSSHOW" });
      dispatch({ type: "SET_LOADING", loading: false });
      console.log(
        "Phone num subbmitted, confirmationResult :",
        confirmationResult
      );
    })
    .catch((error) => {
      console.log("Error while submitting phone number", error);
    });
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Sumbits the code recieved by sms and logs the user in
export const submitCodeSMS = (dispatch, codeSMS, confResult) => {
  dispatch({ type: "SET_LOADING", loading: true });
  confResult
    .confirm(codeSMS.value)
    .then((result) => {
      console.log("SMS code subbmitted");
      dispatch({
        type: "SET_ISNEWUSER",
        isNewUser: result.additionalUserInfo.isNewUser,
      });
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .then((resp) => console.log("test the respo"))
    .catch((error) => {
      console.log("Error while submitting Code sms", error);
    });
};

// Add new locataire
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };
  if (refLoc !== "") {
    db.collection("locataires")
      .doc(refLoc)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  } else {
    db.collection("locataires")
      .doc(`anon-${locataireID}`)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  }
};
