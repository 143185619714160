import React from "react";
import classes from "./CGU.module.css";
import Auxiliary from "../../../hoc/Auxiliary";

const cgu = (props) => {
  return (
    <Auxiliary>
      <h2 className={classes.CGUTitle}>Conditions générales d'utilisation</h2>
      <div className={classes.CGUWrapper}>
        <p>
          Les présentes conditions générales d’utilisation de la plateforme
          (ci-après les « CGU ») s’appliquent à votre utilisation de la
          plateforme web accessible à l’adresse OrsayDemain.corehab.fr.
          <br />
          <br />
          Elles constituent un contrat entre le locataire utilisateur de la
          plateforme (vous) et son éditeur (), que vous acceptez lorsque vous
          vous connectez sur cette plateforme via le formulaire idoine
          (OrsayDemain.corehab.fr/login).
          <br />
          <br />
          Prenez donc le temps de les lire attentivement avant de vous
          connecter. Si elles ne répondent pas à toutes vos questions, vous
          pouvez contacter aux coordonnées indiquées ci-après.
        </p>
        <br />

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>Article 1 : MENTIONS LEGALES</h3>
          <h4 className={classes.ArticleSubtitle}>
            1.1. Editeur de la Plateforme et directeur de la publication
          </h4>
          {/* TODO: Info société + contact */}
          {/* <div className={classes.ArticleContent}>
            <p>
              La plateforme web OrsayDemain.corehab.fr est éditée par la Société
              anonyme d’Habitations à Loyer Modéré de la Réunion (ci-après la «
            »), société anonyme au capital de 8 129 409 € enregistrée au
              Registre du Commerce et des Sociétés de Saint-Denis (Réunion) sous
              le numéro 310 895 172, dont le siège social est situé 31, rue Léon
              Dierx – BP20700 97474 SAINT-DENIS représentée par Monsieur Gilles
              TARDY en sa qualité de Directeur Général.
            </p>
            <p> peut être contactée :</p>
            <p>- Par courrier postal à l’adresse qui précède ;</p>
            <p>- Par courrier électronique à communicati.fr ; ou</p>
            <p>- Par téléphone au 0262 40 10 10.</p>
            <p>
              Le directeur de la publication est Monsieur Gilles TARDY en sa
              qualité de Directeur général de .
            </p>
          </div> */}

          <h4 className={classes.ArticleSubtitle}>
            1.2. Hébergeur de la Plateforme
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              La plateforme est hébergée par la société GOOGLE LLC, dont le
              siège social est situé 1600, Amphitheatre Parkway à Mountain View,
              Californie (ETATS-UNIS). GOOGLE LLC peut être contactée par
              téléphone au +1 650-253-0000 (numéro américain) ; la filiale
              GOOGLE FRANCE peut être contactée au 01 42 68 53 00 (numéro
              français).
            </p>
            <p>Hébergement situé dans l’Union Européenne.</p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            1.3. Développeur de la Plateforme
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              La plateforme est développée et techniquement gérée par la société
              1001RUES, société à responsabilité limitée enregistrée au Registre
              du Commerce et des Sociétés de Paris sous le numéro 812 720 027,
              et dont le siège social est situé 90, rue du Dessous des Berges –
              75013 PARIS.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 2 : DEFINITIONS</h3>

          <div className={classes.ArticleContent}>
            <p>
              Au sein des présentes CGU, les termes et expressions ci-après
              auront les définitions suivantes :
            </p>
            <p>
              <strong>« Avis Locataire » </strong>désigne les avis textuels et
              non textuels saisis par le Locataire sur les fiches concertables
              sur l’Espace de concertation.
            </p>
            <p>
              <strong>« Compte Locataire » </strong>désigne le droit d’accès
              strictement personnel du Locataire à l’Espace de concertation,
              matérialisé par ses Identifiants.
            </p>
            <p>
              <strong>« Contenus Locataire » </strong>désigne tous contenus
              saisis sur la Plateforme par le Locataire, en ce compris (mais
              sans s’y limiter) les Avis Locataire.
            </p>
            <p>
              <strong>« Contrat de bail » </strong>désigne le contrat de bail
              conclu entre le Locataire et l’Editeur.
            </p>
            <p>
              <strong>« Editeur » </strong>désigne l’éditeur de la Plateforme, à
              savo.
            </p>
            <p>
              <strong>« Espace de concertation » </strong>désigne les parties de
              la Plateforme accessibles uniquement aux Locataires, par
              opposition aux parties accessibles à n’importe quel internaute.
            </p>
            <p>
              <strong>« Identifiants » </strong>désigne le couple unique
              d’identifiants permettant à un Locataire d’accéder à l’Espace de
              concertation, constitué par le numéro de téléphone et la Référence
              locataire de ce Locataire.
            </p>
            {/* TODO: update adresse */}
            {/* <p>
              <strong>« Locataire » </strong>désigne tout locataire d’un
              logement du groupe d'habitations OrsayDemain, Rue OrsayDemain 97440
              Saint-André, titulaire à ce titre d’un Contrat de bail en cours de
              validité avec l’Editeur, ou rattaché à un tel Contrat de bail.
            </p> */}
            <p>
              <strong>« Opération de concertation » </strong>désigne l’opération
              de pré-concertation locative réalisée par le biais de l’Espace de
              concertation, dans le cadre de laquelle les Locataires sont
              invités à exprimer leurs avis sur le Projet.
            </p>
            <p>
              <strong>« Parties » </strong>désigne le Locataire et l’Editeur.
            </p>
            {/* TODO: update adresse */}
            {/* <p>
              <strong>« Plateforme » </strong>désigne le projet de travaux,
              objet de l’Opération de concertation, destiné à être réalisé sur
              les logements du groupe d'habitations OrsayDemain, Rue OrsayDemain 97440
              Saint-André.
            </p> */}
            <p>
              <strong>« Référence locataire » </strong>désigne le numéro de
              référence locataire qui se trouve sur les avis mensuels d’échéance
              transmis au Locataire par l’Editeur.
            </p>
            <p>
              Ces termes et expressions pourront être utilisés aussi bien au
              singulier qu’au pluriel, le cas échéant.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 3 : OBJET</h3>
          <div className={classes.ArticleContent}>
            <p>
              Les présentes CGU ont pour objet de préciser le fonctionnement de
              la Plateforme, les conditions de déroulement de l’Opération de
              concertation sur l’Espace de concertation, ainsi que les droits,
              obligations et responsabilités respectifs des Parties dans ce
              cadre.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 4 : TEMPORALITE</h3>
          <div className={classes.ArticleContent}>
            <p>
              Les présentes CGU s’appliquent pendant toute la durée de votre
              utilisation de la Plateforme.
            </p>
            <p>
              Il est précisé que l’Editeur pourra choisir, à sa seule
              discrétion, de désactiver l’accès à l’Espace de concertation voire
              de rendre l’ensemble de la Plateforme inaccessible une fois passé
              la fin de l’Opération de concertation.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 4 : TEMPORALITE</h3>
          <h4 className={classes.ArticleSubtitle}>5.1. Prérequis techniques</h4>
          <div className={classes.ArticleContent}>
            <p>
              L’utilisation de la Plateforme nécessite que le Locataire dispose
              d’un terminal informatique et d’un navigateur web suffisamment
              récents et performants et d’une connexion Internet suffisamment
              puissante au regard des standards techniques en vigueur.
            </p>
            <p>
              Par conséquent, l’Editeur ne peut garantir que la Plateforme sera
              utilisable via tous terminaux et/ou navigateurs, en particulier si
              ces derniers présentent un trop grand degré d’obsolescence au
              regard des standards techniques en vigueur.
            </p>
            <p>
              Les coûts liés à l’acquisition du terminal informatique, du
              navigateur web et de l’abonnement ou de la connexion Internet
              utilisés par le Locataire pour se connecter à la Plateforme sont
              et resteront à la charge exclusive de ce dernier.
            </p>
            <p>
              <strong>Bon à savoir</strong> : Si vous ne disposez pas d’un
              terminal informatique ou d’une connexion Internet (ou si vous
              éprouvez des difficultés à utiliser la Plateforme), des solutions
              d’accompagnement peuvent éventuellement vous être proposées (sans
              toutefois que l’Editeur y soit obligé) ; consultez la page
              d’accueil de la Plateforme pour en savoir plus.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>5.2. Prérequis juridiques</h4>
          <div className={classes.ArticleContent}>
            <p>
              L’accès à l’Espace de concertation est strictement réservé aux
              Locataires, c’est-à-dire aux personnes physiques locataires
              d’un(e) logement/résidence concerné(e) par le Projet et
              titulaires, pour ce(tte) logement/résidence, d’un Contrat de bail
              en cours de validité avec l’Editeur, ou rattachées à un tel
              Contrat de bail.
            </p>
            <p>
              Le Locataire s’engage donc à ne partager ses Identifiants avec
              personne à l’exception des seuls membres de son foyer rattachés au
              Contrat de bail.
            </p>
            <p>
              A l’exception des coûts décrits à l’article 5.1 ci-avant,
              l’utilisation de la Plateforme est gratuite pour le Locataire.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            ARTICLE 6 : FONCTIONNEMENT DE LA PLATEFORME
          </h3>
          <h4 className={classes.ArticleSubtitle}>
            6.1. Création, suspension et suppression du Compte Locataire
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Le Compte Locataire du Locataire sera créé par l’Editeur lui-même,
              sur la base de la Référence locataire du Locataire.
            </p>
            <p>
              Le Locataire sera informé de la création de son Compte Locataire
              par l’Editeur, par tous moyens. Il pourra ensuite se connecter à
              l’Espace de concertation en utilisant ses Identifiants.
            </p>
            <p>
              Dans l’hypothèse où le Locataire partage son logement/sa résidence
              avec une (ou plusieurs) autre(s) personne(s) membre(s) de son
              foyer, le Compte Locataire peut être utilisé indifféremment par le
              Locataire et ce(s) autre(s) membre(s) de son foyer, sous réserve
              que ces derniers soient bien rattachés au Contrat de bail, et à
              charge pour eux de se concerter quant aux Avis Locataires qu’ils
              laisseront sur l’Espace de concertation.
            </p>
            <p>
              L’Editeur se réserve le droit de suspendre le Compte Locataire du
              Locataire en cas de manquement de ce dernier aux présentes CGU
              (par exemple si le Locataire a saisi sur la Plateforme des
              Contenus Locataire illicites ou contraires aux présentes CGU).
            </p>
            <p>
              L’Editeur pourra également supprimer le Compte Locataire du
              Locataire en cas de manquement grave (par exemple si le Locataire
              a partagé ses Identifiants avec une personne autre qu’un membre de
              son foyer rattaché au Contrat de bail).
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            6.2. Fiches non concertables
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              L’Espace de concertation contient des « fiches non concertables ».
              Ces fiches n’ont qu’une fonction informative : elles visent à
              informer le Locataire sur certains aspects du Projet, tel qu’un
              poste de travaux.
            </p>
            <p>
              En toute hypothèse, le contenu des fiches non concertables (qu’il
              soit présenté sous forme de textes, infographies, vidéos, visites
              virtuelles ou sous une autre forme) ne saurait avoir aucune valeur
              contractuelle quant à l’issue effective de l’Opération de
              concertation ou du Projet.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>6.3. Fiches concertables</h4>
          <div className={classes.ArticleContent}>
            <p>
              L’Espace de concertation contient également des « fiches
              concertables ». Ces fiches présentent un ou plusieurs aspects du
              Projet sur lesquels le Locataire peut exprimer un ou plusieurs
              Avis Locataire comme expliqué ci-après.
            </p>
            <p>
              Les Avis Locataire peuvent être exprimés à travers la sélection
              d’une ou plusieurs options prédéterminées par l’Editeur (Avis
              Locataire non textuels) ou à travers la saisie d’un texte libre
              (Avis Locataire textuels).
            </p>
            <p>
              Le Locataire dispose de la faculté de modifier ses Avis Locataires
              à tout moment avant de les valider pour la fiche concertable
              concernée. Une fois un Avis Locataire validé, celui-ci est reçu et
              enregistré par l’Editeur, qui pourra l’analyser et l’utiliser dans
              le cadre du Projet comme indiqué par ailleurs dans les présentes
              CGU.
            </p>
            <p>
              Les fiches concertables pour lesquelles le Locataire a validé ses
              Avis Locataires apparaissent marquées d’une icône sur l’Espace de
              concertation.
            </p>
            <p>
              En toute hypothèse, le contenu interactif et non interactif des
              fiches concertables (qu’il soit présenté sous forme de textes,
              infographies, vidéos, visites virtuelles ou sous une autre forme)
              n’a qu’une fonction informative et ne saurait avoir aucune valeur
              contractuelle quant à l’issue effective de l’Opération de
              concertation ou du Projet.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            6.4. Issue de l’Opération de concertation
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              La date de fin de l’Opération de concertation est librement fixée
              par l’Editeur.
            </p>
            <p>
              Passé cette date, le Locataire ne pourra plus saisir d’Avis
              Locataire, et l’Editeur se réserve le droit de désactiver l’accès
              à l’Espace de concertation ou à la Plateforme dans son ensemble.
            </p>
            <p>
              L’Editeur rassemblera les Avis Locataire des différents Locataires
              et les analysera dans la perspective de la réalisation du Projet,
              sous réserve de leur pertinence, de leur faisabilité et de leur
              cohérence, lesquelles sont laissées à la libre appréciation de
              l’Editeur.
            </p>
            <p>
              Le Locataire comprend et accepte que ses Avis Locataire ne seront
              pas forcément suivis d’effet dans le cadre du Projet, et que
              l’Editeur ne saurait avoir aucune obligation de s’y conformer ou
              de les mettre en œuvre, l’objectif de l’Opération de concertation
              étant d’obtenir une vue globale des opinions des différents
              Locataires, et non d’appliquer individuellement les demandes de
              chaque Locataire dans le cadre du Projet.
            </p>
            <p>
              L’Editeur pourra, à sa discrétion et, le cas échéant, sous la
              forme de son choix, éditer et publier un rapport d’analyse des
              Avis Locataire des différents Locataires, dans le but d’informer
              ces derniers sur les suites du Projet.
            </p>
            <p>
              Les Avis Locataire et les analyses qui en sont faites pourront
              également être partagées avec tous prestataires et partenaires de
              l’Editeur dans le cadre du Projet.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            ARTICLE 7 : OBLIGATIONS DES PARTIES
          </h3>
          <h4 className={classes.ArticleSubtitle}>
            7.1. Obligations du Locataire
          </h4>
          <h4 className={classes.ArticleSubtitle}>
            7.1.1. Identifiants et Compte Locataire
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Le Locataire s’engage à assurer la confidentialité de ses
              Identifiants ; il s’interdit expressément de partager ces
              Identifiants avec un quelconque tiers à la seule exception des
              membres de son foyer rattachés au Contrat de bail.{" "}
            </p>
            <p>
              En cas d’accès frauduleux ou non autorisé à son Compte Locataire
              et/ou de perte, vol, corruption ou divulgation non autorisée de
              ses Identifiants, le Locataire s’engage à en informer l’Editeur
              sans délai.
            </p>
            <p>
              Si le Locataire permet à un quelconque tiers (en ce compris les
              autres membres de son foyer rattachés au Contrat de bail)
              d’utiliser son Compte Locataire, que ce soit délibérément ou par
              négligence, le Locataire se porte fort du respect de l’ensemble
              des présentes CGU par ce tiers. En toute hypothèse, toute
              utilisation de la Plateforme par l’intermédiaire du Compte
              Locataire du Locataire sera présumée avoir été effectuée par le
              Locataire lui-même.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>7.1.2. Contenus Locataire</h4>
          <div className={classes.ArticleContent}>
            <p>
              Est expressément interdite la saisie de tout Contenu Locataire (en
              ce compris tout Avis Locataire) :
            </p>
            <p>
              - Présentant un caractère raciste, antisémite, négationniste,
              sexiste, handiphobe ou autrement discriminatoire ;
            </p>
            <p>
              - Constitutif d’incitation ou de provocation à la haine, à la
              violence ou à la commission d’infractions ;
            </p>
            <p>- Présentant un caractère injurieux ou diffamatoire ;</p>
            <p>
              - Portant atteinte à la vie privée ou aux droits de la
              personnalité d’un autre Locataire ou d’un quelconque tiers ;
            </p>
            <p>
              - Constitutif de contrefaçon d’un droit de propriété
              intellectuelle ;
            </p>
            <p>
              - Autrement contraire aux lois et réglementations applicables ou
              portant autrement atteinte aux droits, libertés et intérêts de
              l’Editeur, d’un autre Locataire ou d’un quelconque tiers.
            </p>
            <p>
              Les Contenus Locataire contraires à l’interdiction qui précède
              pourront être retirés de la Plateforme par l’Editeur sans préavis
              et portés à la connaissance des autorités et juridictions
              compétentes.
            </p>
            <p>
              Sont également interdits les Contenus Locataire ne présentant pas
              de rapport direct avec le Projet. Ces Contenus Locataire pourront
              être retirés de la Plateforme par l’Editeur sans préavis.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            7.2. Obligations de l’Editeur
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              L’Editeur s’engage à mettre en œuvre ses meilleurs efforts pour
              assurer la disponibilité et la sécurité de la Plateforme.{" "}
            </p>
            <p>
              Toutefois, compte tenu de la nature des ressources techniques et
              protocoles Internet mobilisés pour la mise en ligne de la
              Plateforme, le Locataire comprend et accepte que l’Editeur ne
              saurait stipuler aucune garantie ni aucune obligation de résultat
              quant à la disponibilité et la sécurité de la Plateforme, ni
              garantir d’une quelconque manière que la Plateforme sera toujours
              exempte de failles, ralentissements, interruptions, dégradations,
              vulnérabilités ou dysfonctionnements.
            </p>
            <p>
              Par ailleurs, l’Editeur ne saurait en aucun cas être tenu de
              mettre effectivement en œuvre, dans le cadre de la réalisation du
              Projet, les informations et propositions présentées sur la
              Plateforme, lesquelles n’ont qu’une valeur informative et ne sont
              indiquées que pour les seuls besoins de la concertation.{" "}
            </p>
            <p>
              De même, l’Editeur ne saurait en aucun cas être tenu de mettre
              effectivement en œuvre, dans le cadre de la réalisation du Projet,
              les Avis Locataire du Locataire, ni de démontrer ou prouver la
              prise en compte de ces Avis Locataire d’une quelconque manière.
            </p>
            <p>
              En toute hypothèse, de manière générale, les obligations de
              l’Editeur au titre des présentes CGU ne peuvent constituer que des
              obligations de moyens, et en aucun cas de résultat.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 8 : RESPONSABILITE</h3>
          <h4 className={classes.ArticleSubtitle}>
            8.1. Responsabilité du Locataire
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Le Locataire s’engage à tenir l’Editeur indemne de toute action,
              plainte, réclamation ou demande d’un quelconque tiers ainsi que de
              toute sanction ou poursuite d’une quelconque juridiction ou
              autorité qui aurait pour cause, origine ou fondement un quelconque
              manquement du Locataire aux présentes CGU et/ou aux lois et
              réglementations applicables.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            8.2. Responsabilité de l’Editeur
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              L’Editeur ne saurait être tenu responsable de réparer un
              quelconque dommage dans la mesure où ce dommage aurait pour cause
              ou origine :
            </p>
            <p>
              - Un manquement du Locataire lui-même à ses obligations au titre
              des présentes CGU (par exemple si le Locataire a partagé ses
              Identifiants avec un tiers autre qu’un membre de son foyer
              rattaché au Contrat de bail) et/ou à ses obligations légales ou
              réglementaires ;
            </p>
            <p>
              - Un(e) fait, action ou omission, imprévisible et insurmontable,
              d’un tiers étranger à l’exécution des présentes CGU ; et/ou{" "}
            </p>
            <p>- Un cas de force majeure.</p>
            <p>
              En toute hypothèse, la responsabilité de l’Editeur au titre des
              présentes CGU est strictement limitée à l’indemnisation des seuls
              dommages (i) personnellement subis par le Locataire, (ii) qui
              étaient prévisibles lors de la conclusion du Mandat et (iii) qui
              sont une suite directe et immédiate d’un manquement prouvé de
              l’Editeur à ses obligations au titre des CGU, les trois conditions
              ci-avant étant cumulatives.{" "}
            </p>
            <p>
              De convention expresse, ne sauraient être considérés comme des
              dommages prévisibles au sens du paragraphe précédent, et par
              conséquent entraîner une quelconque réparation, les dommages qui
              résulteraient d’une absence de prise en compte d’un Avis Locataire
              par l’Editeur, étant rappelé que ce dernier ne saurait être tenu
              d’aucune obligation à cet égard.{" "}
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            ARTICLE 9 : PROPRIETE INTELLECTUELLE
          </h3>
          <h4 className={classes.ArticleSubtitle}>9.1. Plateforme</h4>
          <div className={classes.ArticleContent}>
            <p>
              L’ensemble des images, textes, infographies, vidéos, chartes
              graphiques, pistes sonores, logos, marques, dénominations sociales
              et noms commerciaux publiés sur la Plateforme sont la propriété
              exclusive, respectivement, de l’Editeur, du développeur de la
              Plateforme visé à l’article 1.3 ci-avant, ou de tiers ayant
              valablement autorisé l’Editeur et/ou ce développeur à les
              utiliser.
            </p>
            <p>
              Le code informatique constitutif de la Plateforme est quant à lui
              la propriété exclusive du développeur de la Plateforme.
            </p>
            <p>
              Les présentes CGU n’emportent aucun transfert de propriété ni
              aucun octroi de licence sur l’un quelconque de ces éléments au
              profit du Locataire, qui s’en interdit dès lors tout usage autre
              que pour les seuls besoins de l’utilisation de la Plateforme
              conformément aux présentes CGU, à peine des sanctions civiles et
              pénales prévues en matière de contrefaçon.{" "}
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>9.2. Contenus Locataire</h4>
          <div className={classes.ArticleContent}>
            <p>
              Le Locataire cède à l’Editeur le droit de reproduire et
              représenter ses Contenus Locataire par tous moyens et sur tous
              supports, aux fins d’organiser l’Opération de concertation et, le
              cas échant, d’éditer le rapport d’analyse visé à l’article 6.4
              ci-avant, dans tous pays et à titre gratuit, pour la durée légale
              ou réglementaire des droits de reproduction et de représentation
              correspondants.{" "}
            </p>
            <p>
              L’Editeur sera également en droit d’autoriser tous tiers de son
              choix, en ce compris le développeur visé à l’article 1.3, à
              reproduire et représenter ces Contenus Locataire à ces mêmes fins
              et dans ces mêmes conditions.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            ARTICLE 10 : DONNEES A CARACTERE PERSONNEL
          </h3>
          <div className={classes.ArticleContent}>
            <p>
              Le Locataire est informé que son utilisation de la Plateforme
              implique la collecte et le traitement d’un certain nombre de
              données à caractère personnel le concernant, ainsi qu’il est
              décrit dans la Politique de confidentialité de la Plateforme.
            </p>
            <p>
              De même, le Locataire est informé que des cookies et autres
              technologies du même type sont utilisés sur la Plateforme, ainsi
              qu’il est décrit dans la Politique cookies de la Plateforme.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 11 : CORRESPONDANCES</h3>
          <div className={classes.ArticleContent}>
            <p>
              Toute demande ou communication relative aux présentes CGU, à la
              Plateforme, à l’Opération de concertation ou au Projet doit être
              adressée à l’Editeur en utilisant ses coordonnées indiquées sous
              l’article 1 ci-avant.
            </p>
            <p>
              Toute communication à Locataire sera, sauf demande préalable
              contraire, expresse et écrite de la part de ce Locataire, réputée
              dûment reçue si elle est envoyée par l’Editeur à l’adresse de son
              domicile ou au numéro de téléphone inclus dans ses Identifiants.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            ARTICLE 12 : RECLAMATIONS ET LITIGES
          </h3>
          <h4 className={classes.ArticleSubtitle}>12.1. Réclamations</h4>
          <div className={classes.ArticleContent}>
            <p>
              Le Locataire peut adresser à l’Editeur toutes réclamations
              relatives à la Plateforme, à l’Opération de concertation ou au
              Projet en utilisant pour ce faire les coordonnées de l’Editeur
              telles qu’indiquées sous l’article 1 ci-avant.
            </p>
            <p>
              L’Editeur accusera réception de la réclamation et y répondra dans
              les meilleurs délais. En tant que de besoin, il pourra demander au
              Locataire des informations supplémentaires pour le traitement de
              sa réclamation ; le Locataire s’engage, dans cette hypothèse, à
              fournir l’ensemble des informations demandées sans délai
              injustifié, de manière complète et compréhensible.
            </p>
            <p>
              L’envoi d’une réclamation à l’Editeur est sans préjudice du droit,
              pour le Locataire, de saisir le médiateur de la consommation ou un
              tribunal comme stipulé ci-après.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            12.2. Loi applicable et juridiction compétente
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Tout litige relatif à l’existence, la validité, l’interprétation,
              l’exécution et/ou la résiliation des présentes CGU, s’il est porté
              en justice, sera impérativement soumis à la compétence exclusive
              des tribunaux de Saint-Denis de La Réunion, nonobstant toute
              hypothèse d’appel en garantie et/ou de pluralité de défendeurs, et
              sera tranché par application de la loi française.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            ARTICLE 13 : STIPULATIONS DIVERSES
          </h3>
          <h4 className={classes.ArticleSubtitle}>
            13.1. Droit de rétractation
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Le Locataire dispose, à l’égard de son utilisation de la
              Plateforme, d’un droit de rétractation, qu’il peut exercer
              simplement en cessant d’utiliser la Plateforme à tout moment. Il
              peut également, s’il le souhaite, contacter l’Editeur aux fins que
              soit supprimé son Compte Locataire.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>13.2. Intégralité</h4>
          <div className={classes.ArticleContent}>
            <p>
              Les présentes CGU traduisent l’intégralité du rapport contractuel
              entre l’Editeur et le Locataire quant à l’objet défini à l’article
              3 ci-avant.{" "}
            </p>
            <p>
              L’ensemble des informations apparaissant sur la Plateforme, à
              l’exception des présentes CGU, sont donc entièrement dépourvues de
              valeur contractuelle et ne peuvent avoir, au plus, qu’une valeur
              informative.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>13.2. Intégralité</h4>
          <div className={classes.ArticleContent}>
            <p>
              Les données et informations stockées sur les serveurs et dans les
              systèmes d’information détenus et/ou placés sous le contrôle
              direct de l’Editeur ou de ses sous-traitants prévaudront, à titre
              de preuve, pour toute question relative à l’exécution des
              présentes CGU, à l’exclusion de tou(te)s autres informations,
              données, documents et/ou échanges écrits ou oraux entre les
              Parties.
            </p>
            <p>
              Ces données et informations ne pourront être écartées qu’à la
              condition, pour le Locataire, de démontrer valablement, par la
              production de toutes preuves écrites, que lesdites données et/ou
              informations sont inexactes, incomplètes, corrompues ou
              falsifiées.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>13.4. Nullité partielle</h4>
          <div className={classes.ArticleContent}>
            <p>
              Dans le cas où l’une des clauses des présentes CGU serait annulée
              par l’autorité judiciaire compétente, cette annulation ne saurait
              avoir aucune incidence sur l’existence et la force obligatoire des
              autres clauses non affectées par la décision de l’autorité en
              question.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>13.5. Mise à jour</h4>
          <div className={classes.ArticleContent}>
            <p>
              L’Editeur se réserve le droit de mettre à jour les présentes CGU à
              tout moment, en modifiant la version des CGU publiée sur la
              Plateforme à date.{" "}
            </p>
            <p>
              Dans un tel cas, les Locataires seront informés de la mise à jour
              des CGU lors de leur première connexion à l’Espace de concertation
              suivant la mise à jour, et invités à y consentir à cette occasion.
            </p>
            <p>
              Dans le cas où le Locataire ne consentirait pas aux nouvelles CGU,
              son Compte Locataire sera suspendu jusqu’à ce qu’il manifeste, le
              cas échéant, son consentement à ces nouvelles CGU auprès de
              l’Editeur.
            </p>
            <p>
              Les présentes CGU ont été mises à jour pour la dernière fois le 18
              août 2020.
            </p>
          </div>
        </div>
      </div>
      {/* <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>ARTICLE 6 :	FONCTIONNEMENT DE LA PLATEFORME</h3>
          <h4 className={classes.ArticleSubtitle}>6.1.	Création, suspension et suppression du Compte Locataire</h4>
          <div className={classes.ArticleContent}>
            
          </div>
        </div> */}
    </Auxiliary>
  );
};

export default cgu;
